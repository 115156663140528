import React, { useState,useEffect } from 'react';
import axios from 'axios'
import {Redirect} from 'react-router-dom'
import {SpinnerLoaderCorso} from '../details/SpinnerLoader';
import Modal from 'react-modal';
import HeaderCorsi from './HeaderCorsi';

function compare(a, b) {
  if (a.position > b.position) return 1;
  if (b.position > a.position) return -1;
  return 0;
}
const customStyles = {
  content : {
    marginTop :"30px",
  }
};
function Minicorso(props) {
  const [selectedLession, setSelectedLession] = useState({lezione:null})
  const [listOfLession, setListOfLession] = useState(null)
  const [corso, setCorso] = useState(null)
  const [selectedModules, setSelectedModules] = useState(null)
  const [loading, setLoading] = useState(undefined)
  const [message, setMessage] = useState("")
  const [modalOpen, setModalOpen] = useState(false)
  
  const [paidComplete, setPaidComplete] = useState(undefined)

  function selezionaModulo(modulo){
    if(corso){
      if(selectedModules && modulo.id === selectedModules.id){
        setSelectedModules(null)
      }else{
        setSelectedModules(modulo)
      }
      
    }
  }

  function selezionaLezione(lezione){
    if(lezione.linkvideo_mp4){
      let data={}
      data["mp4"] = lezione.linkvideo_mp4
      axios.post("/api/v1/GetVideoBlob/",data).then(res=>{
        lezione.new_linkvideo_mp4 = lezione.linkvideo_mp4
        lezione.linkvideo_mp4=res.data.new_url
        setSelectedLession({...selectedLession, lezione:lezione})
      }).catch(error=>{
        console.log(error)
      })      
    }else{
      setSelectedLession({...selectedLession, lezione:lezione})
    }
  }

  function setLezionePrecedente(){
    let previus = listOfLession.find(x=>x.position === Number(selectedLession.lezione.position)-1)
    if(previus) {
      selezionaLezione(previus)
    }
  }

  function setModuloSuccessivo(){
    let next = corso.modulo_set.find(x=>x.position === Number(selectedModules.position)+1)
    if(next) {
      setSelectedModules(next)
    }
  }

  function setLezioneSuccessiva(){
    let next = listOfLession.find(x=>x.position === Number(selectedLession.lezione.position)+1)
    if(next) {
      selezionaLezione(next)
    }else{
      setModuloSuccessivo()
    }
  }

  useEffect(() => {
    let slugcorso = props.match.params.slugcorso
    if(!props.match.params.slugcorso){
      window.location.href = "/404"
    }

    axios.get("/api/v1/minicorso/"+props.match.params.slugcorso+"/").then((res)=>{ 
      //TODO: //devo accedere senza header, quindi fare nuovo modulo per recuperare il solo minicorso
      setCorso(res.data)
      if(res.data.modulo_set.length === 1){
        setSelectedModules(res.data.modulo_set[0])
      }
      }).catch((error)=>{
        window.location.href = "/404"
      })
  }, [])

  useEffect(() => {
    if(selectedModules){
      setListOfLession(null)
      axios.get("/api/v1/modulominicorso/"+selectedModules.id+"/").then((res)=>{
        setListOfLession(res.data.lezione_set)
      }).catch((error)=>{
        setMessage("Errore, riprovare")
      })
    }
  }, [selectedModules])

  return (
      <>
      {corso ? 
        <div className="row height-forced" style={{margin:"-10px 0"}}>
          <Modal style={customStyles} isOpen={modalOpen}>
          <div>
            <div style={{textAlign:"right",marginTop:"-15px",padding:"10px 0"}} onClick={()=>{setModalOpen(!modalOpen)}}><b>X</b> chiudi</div>
            {corso.modulo_set.sort(compare).map((modulo,i)=>{
              return(
                <div key={i} style={{paddingBottom:"0"}}>
                  <b onClick={()=>{selezionaModulo(modulo)}}><h1 className="corso-menu-titolo-mobile" style={{color:corso.color}}>{modulo.title}</h1></b>
                  {selectedModules && modulo.id === selectedModules.id ?
                   <div style={{borderTop:"1px solid",borderColor:corso.color}}>
                    <h5 className="corso-menu-sottotiolo" style={{color:corso.ButtonColor}}>{modulo.subtitle}</h5>
                    {!listOfLession ? 
                      <SpinnerLoaderCorso color={corso.ButtonColor} />
                    :null}
                    {listOfLession && listOfLession.sort(compare).map((lezione,i)=>{
                      return(
                        <div key={i} style={{textAlign:"center"}} onClick={()=>{selezionaLezione(lezione);setModalOpen(!modalOpen)}}>
                          <div className="lezione-item" style={selectedLession.lezione && selectedLession.lezione.id === lezione.id ?  {border:"2px solid ",borderColor:corso.color, color:corso.TextButtonColor,backgroundColor: corso.ButtonColor}:{border:"2px solid", borderColor:corso.ButtonColor ,color:corso.TextButtonColor,backgroundColor: corso.ButtonColor ,cursor:"pointer"}}>
                            <span style={{ marginLeft: "10px"}}>{lezione.title}</span>
                          </div>
                        </div>
                      )
                    })}
                    </div>
                  :null}
                </div>
              )
            })}
          </div>               
          </Modal>

          <div className="col-lg-3 d-none d-lg-block left-corso">
            {corso.modulo_set.sort(compare).map((modulo,i)=>{
              return(
                <div key={i} style={{paddingBottom:"0"}}>
                  <b onClick={()=>{selezionaModulo(modulo)}}><h1 className="corso-menu-titolo" style={{color:corso.color}}>{modulo.title}</h1></b>
                  {selectedModules && modulo.id === selectedModules.id ?
                   <div style={{borderTop:"3px solid",borderColor:corso.color}}>
                    <h5 className="corso-menu-sottotiolo" style={{color:corso.ButtonColor}}>{modulo.subtitle}</h5>
                    {!listOfLession ? 
                      <SpinnerLoaderCorso color={corso.ButtonColor}/>
                    :null}
                    {listOfLession && listOfLession.sort(compare).map((lezione,i)=>{
                      return(
                        <div key={i} style={{textAlign:"center"}} onClick={()=>selezionaLezione(lezione)}>
                          <div className="lezione-item" style={selectedLession.lezione && selectedLession.lezione.id === lezione.id ? {border:"2px solid ",borderColor:corso.color, color:corso.TextButtonColor,backgroundColor: corso.ButtonColor}:{border:"2px solid", borderColor:corso.ButtonColor ,color:corso.TextButtonColor,backgroundColor: corso.ButtonColor ,cursor:"pointer"}}>
                            <span style={{ marginLeft: "10px"}}>{lezione.title}</span>
                          </div>
                        </div>
                      )
                    })}
                    </div>
                  :null}
                </div>
              )
            })}
          </div>
          <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12 right-corso" style={{marginTop:"-15px"}}>
            <div className="col-12" style={{paddingTop:"10px"}}>
              {/* <div >
                <HeaderCorsi></HeaderCorsi>
              </div> */}
              <hr/>
            </div>
            <div className="col-lg-4 d-block d-lg-none" style={{textAlign:"center"}}>
              <span onClick={()=>{setModalOpen(!modalOpen)}} className="button-newsletter" style={{fontSize:"22px",width:"100%",maxWidth:"50%",color:corso.TextButtonColor, padding:"5px",backgroundColor:corso.ButtonColor}}><i className="fas fa-bars"></i> MODULI</span>
              
            </div>
            <div className="row">
            {selectedLession.lezione === null ?
              <>
                <div className="col-12 corso-titolo">
                  <h1 style={{color:corso.color,fontSize:"60px",textAlign:"center"}}>{corso.title}</h1>
                </div>
                <div className="col-12">
                  <div className="col-12">
                    <div style={{textAlign:"left",margin:"0 auto"}} dangerouslySetInnerHTML={{ __html: corso.subtitle}}></div>
                  </div>
                  <hr/>
                  <div className="col-12" style={{textAlign:"center",fontSize:"25px",marginTop:"20px"}}>Benvenut*</div>
                </div>
              </>
             :
             <div className="container" style={{textAlign:"center"}}>
               <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{margin:"0 auto"}}>
                <h3 className={"lezione-titolo"} style={{color:corso.color}}>{selectedLession.lezione.title}</h3>
                {selectedLession.lezione.title ? <span className={"lezione-titolo"}>{selectedLession.subtitle}</span>:null}
                {selectedLession.lezione.link_vimeo ? 
                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                      <iframe title="Giorgia Graziani - Evento" 
                        src={selectedLession.lezione.link_vimeo}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                        }} 
                        frameborder="0"    
                        allowfullscreen>  
                      </iframe>
                    </div>
                : selectedLession.lezione.linkvideo_mp4 || selectedLession.lezione.linkvideo_webm ? 
                  <div style={{marginTop:"15px"}}>
                    <video key={selectedLession.lezione.linkvideo_webm ? selectedLession.lezione.linkvideo_webm : selectedLession.lezione.new_linkvideo_mp4} 
                    controls style={{maxHeight:"600px",width:"100%"}} controlsList="nodownload" poster={selectedLession.lezione.videoimage ? selectedLession.lezione.videoimage :""}>
                      {selectedLession.lezione.new_linkvideo_mp4 ? <source src={selectedLession.lezione.new_linkvideo_mp4} type="video/mp4" /> : null}
                      {selectedLession.lezione.linkvideo_webm ? <source src={selectedLession.lezione.linkvideo_webm} type="video/webm" /> : null}
                      {selectedLession.lezione.linkvideo_mp4 ? <source src={selectedLession.lezione.linkvideo_mp4} type="video/mp4" /> : null}
                      
                      Sorry, your browser doesn't support embedded videos.
                    </video>
                  </div> 
                : null}
                {selectedLession.lezione.file ? 
                  <div className={!selectedLession.lezione.linkvideo_mp4 && !selectedLession.lezione.linkvideo_webm ? "padding-pdf-50" : "padding-pdf-15"}>
                    <a href={selectedLession.lezione.file} className="linkpdf" target="_blank" rel="noopener noreferrer">
                      <span className="lezione-item" style={{padding:"15px",backgroundColor:"#053861", color:"#FFFFFF"}}>Scarica il documento in pdf &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className="fas fa-arrow-down"></i></span></a>
                  </div> 
                : null}
                <div style={{textAlign:"center"}}>
                <div dangerouslySetInnerHTML={{ __html: selectedLession.lezione.descrizione}}/>
                </div>
              </div>
              <div className="row button-lezioni-arrow">
                <div className="col-6">
                  {selectedLession.lezione.position>0 ?
                    <span className="span-bottone" onClick={()=>setLezionePrecedente()} style={{color:corso.TextButtonColor,backgroundColor:corso.ButtonColor}}>Lezione precedente</span>
                  :null}
                  </div>
                <div className="col-6"><span className="span-bottone" onClick={()=>setLezioneSuccessiva()} style={{color:corso.TextButtonColor,backgroundColor:corso.ButtonColor}}>Lezione successiva</span></div>
              </div>
            </div>}
          </div>
          </div>
      </div>
      :null}
     </>
  );
}

export default Minicorso;
